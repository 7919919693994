<template>
    <page-title
        title="スキル一覧"
        icon="bi-stars"
    >
    </page-title>

    <inline-loader v-if="loading"></inline-loader>
    <section class="section" v-else>
        <table class="table  table-striped mb-4">
            <thead>
                <tr class="table-primary">
                    <th class="text-center col-md-16">スキル名称</th>
                    <th class="text-center col-md-4">並び順</th>
                    <th class="col-md-4"></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="skill, index in skills" :key="skill">
                    <td class="">
                        {{ skill.skill_name }}
                    </td>
                    <td class="text-center">
                        {{ skill.priority }}
                    </td>
                    <td class="text-center">
                        <button-exec
                            text="編集..."
                            icon="bi-pencil-square"
                            @click="openModal(skill, index)"
                        ></button-exec>
                    </td>
                </tr>
            </tbody>
            <tfoot></tfoot>
        </table>
        <div class="ms-2">
            <button-exec
                text="新規登録..."
                icon="bi-plus-lg"
                @click="openModal()"
            ></button-exec>
        </div>
    </section>

    <!-- モーダル -->
    <div v-if="modal">
        <transition name="fade">
            <div v-if="modal">
                <div class="modal-backdrop show"></div>
                <div class="modal" tabindex="-1" style="display:block">
                    <div class="modal-dialog" style="max-width: 70% !important">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h4 class="modal-title">
                                    {{ modal_title }}
                                </h4>
                                <button type="button" class="btn-close" @click="cancelEdit"></button>
                            </div>
                            <div class="modal-body">
                                <form @submit.prevent="save" id="modal_form" class="row">
                                    <div class="form-group col-md-6">
                                        <label>スキル名称</label>
                                        <form-input
                                            v-model="modal_skill.skill_name"
                                            :required="true"
                                        ></form-input>
                                    </div>

                                    <div class="form-group col-md-6">
                                        <label>並び順</label>
                                        <form-input-number
                                            v-model="modal_skill.priority"
                                            :required="true"
                                            min="0"
                                            max="9999"
                                            step="1"
                                        ></form-input-number>
                                    </div>
                                </form>
                            </div>
                            <div class="modal-footer d-flex justify-content-between">
                                <button-exec-create
                                    v-if="!modal_skill.skill_id"
                                    size="btn-lg"
                                    form="modal_form"
                                ></button-exec-create>
                                <button-exec-update
                                    v-else
                                    size="btn-lg"
                                    form="modal_form"
                                ></button-exec-update>
                                <button-exec-delete
                                    v-if="modal_skill.skill_id"
                                    size="btn-lg"
                                    @click="$refs.confirm_remove.show()"
                                ></button-exec-delete>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>

    <confirm-dialog ref="confirm_remove" @ok="remove()">
        <p>削除した場合、スキルに紐づく応援カメラマンのデータ(技量、備考)も消えてしまいます。</p>
        <p>削除してよろしいですか？</p>
    </confirm-dialog>

</template>

<script>
import PageTitle from '@/components/PageTitle';
import Skill from '@/models/entities/skill';
import ConfirmDialog from '@/components/tools/ConfirmDialog';
import InlineLoader from '@/components/tools/InlineLoader';
import FormInput from '@/components/forms/FormInput.vue';
import FormInputNumber from '@/components/forms/FormInputNumber.vue';
import ButtonExec from '@/components/buttons/ButtonExec';
import ButtonExecDelete from '@/components/buttons/ButtonExecDelete';
import ButtonExecCreate from '@/components/buttons/ButtonExecCreate.vue';
import ButtonExecUpdate from '@/components/buttons/ButtonExecUpdate.vue';

export default {
    name: 'Skill',
    components: {
        PageTitle,
        ConfirmDialog,
        FormInput,
        FormInputNumber,
        InlineLoader,
        ButtonExec,
        ButtonExecDelete,
        ButtonExecCreate,
        ButtonExecUpdate,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showMessage'
    ],
    data() {
        return {
            loading: 0,
            skills: [],
            modal: false,
            modal_skill: new Skill(),
            original_skill: new Skill(),
            modal_index: null,
            modal_title: null,
        }
    },
    mounted() {
        this.fetchSkillList();
    },
    methods: {
        fetchSkillList() {
            this.loading++;
            this.$http.get(`/skills`)
            .then(response => {
                for (let row of response.data) {
                    this.skills.push(new Skill(row));
                }
            })
            .finally(() => {
                this.loading--;
            });
        },
        openModal(skill = null, index = null) {
            this.modal = true;
            this.modal_index = index;
            if (skill) {
                this.modal_title = 'スキル編集';
                this.modal_skill = skill;
                this.original_skill = JSON.stringify(skill);
                this.original_skill = JSON.parse(this.original_skill);
            } else {
                this.modal_title = 'スキル登録';
                this.modal_skill = new Skill();
                this.modal_skill.priority = 9999;
            }
        },
        cancelEdit() {
            if (this.modal_skill.skill_id) {
                let target_index = this.skills.findIndex(skill => skill.skill_id === this.original_skill.skill_id);
                this.skills[target_index].skill_name = this.original_skill.skill_name;
                this.skills[target_index].priority = this.original_skill.priority;

                this.original_skill = new Skill();
            }
            this.closeModal();
        },
        closeModal() {
            this.modal = false;
        },
        save() {
            if (this.modal_skill.skill_id) {
                this.startScreenLoading();
                this.$http.put(`/skills/${this.modal_skill.skill_id}`, this.modal_skill)
                .then((response) => {
                    let updated_skill = new Skill(response.data);

                    // 削除して追加
                    let deleteIndex = this.skills.findIndex(skill => skill.skill_id === updated_skill.skill_id);
                    this.skills.splice(deleteIndex, 1);
                    this.pushAndSortByPriority(updated_skill);

                    this.showMessage('編集しました');
                    this.modal_skill = new Skill();
                })
                .finally(() => {
                    this.endScreenLoading();
                    this.closeModal();
                });
            } else {
                this.startScreenLoading();
                this.$http.post(`/skills`, this.modal_skill)
                .then(response => {
                    let created_skill = new Skill(response.data);
                    this.pushAndSortByPriority(created_skill);

                    this.showMessage('登録しました');
                    this.modal_skill = new Skill();
                })
                .finally(() => {
                    this.endScreenLoading();
                    this.closeModal();
                })
            }
        },
        remove() {
            this.startScreenLoading();
            this.$http.delete(`/skills/${this.modal_skill.skill_id}`)
            .then(() => {
                this.showMessage('削除しました');
                this.skills.splice(this.modal_index, 1);
            })
            .finally(() => {
                this.endScreenLoading();
                this.closeModal();
            });
        },
        pushAndSortByPriority(saved_skill) {
            let priority = saved_skill.priority;
            let index = this.skills.findIndex(skill => skill.priority > priority);

            if (index < 0) {
                this.skills.push(saved_skill);
            } else {
                this.skills.splice(index, 0, saved_skill);
            }
        }
    }
}
</script>

<style scoped>

</style>
